<template>
  <div class="finance-page">
    <SimpleHeader></SimpleHeader>
    <div class="ml flex align-center">
      <el-button type="text" class="el-icon-back fs16" @click="$router.go(-1)"> {{ $t(207) }}</el-button>
      <div class="tabs">
        <div class="item"
          :class="{active: curType===1}"
          @click="switchType(1)">{{ $t(242) }}</div>
        <div class="item"
          :class="{active: curType===2}"
          @click="switchType(2)">{{ $t(243) }}</div>
        <div class="item"
          :class="{active: curType===3}"
          @click="switchType(3)">{{ $t(244) }}</div>
      </div>
    </div>
    <div class="fund-box">
      <div class="s-head flex align-center">
        <div class="flex1">{{ $t(curType===1?242:(curType===2?243:244)) }}</div>
        <!-- <el-select></el-select> -->
      </div>
      <div class="scroll-head flex">
        <div class="head-item">{{ $t(247) }}</div>
        <div class="head-item">{{ $t(196) }}</div>
        <div class="head-item">{{ $t(245) }}</div>
        <div class="head-item" v-if="curType!==3">{{ $t(204) }}</div>
        <div class="head-item" v-if="curType!==3">{{ $t(246) }}</div>
        <div class="head-item" v-if="curType===3">{{ $t(239) }}</div>
        <div class="head-item" v-if="curType===3">{{ $t(240) }}</div>
      </div>
      <div class="infinite-list-wrapper" style="overflow:auto">
        <ul
          class="list"
          v-infinite-scroll="loadData"
          infinite-scroll-disabled="disabled">
          <li v-for="item in dataList" class="list-item flex" :key="item.key">
            <div class="cell">{{ filterTime(item.createTime) }}</div>
            <div class="cell">{{ item.coin }}</div>
            <div class="cell">{{ item.amount }}</div>
            <div class="cell" v-if="curType!==3">{{ item.comment || '--' }}</div>
            <div class="cell" v-if="curType!==3">{{ filterStatusName(item.status) }}<span v-if="item.status===3&&item.auditComment" class="fs12">({{ item.auditComment }})</span></div>
            <div class="cell" v-if="curType===3">{{ filterCateName(item.fromCategory) }}</div>
            <div class="cell" v-if="curType===3">{{ filterCateName(item.toCategory) }}</div>
          </li>
        </ul>
        <div class="empty text-center pt20" v-if="dataList.length===0 && !loading">
          <img src="../../assets/img/nodata.png" alt="" style="width:140px;height: auto;"><br>
          <p class="color999">{{ $t(255) }}</p>
        </div>
        <p v-if="loading" class="text-center color999">{{ $t(186) }}...</p>
        <p v-if="noMore && this.dataList.length > 20" class="text-center pd10 pt10 color999">{{ $t(249) }}</p>
      </div>
    </div>
    <SimpleFooter></SimpleFooter>
  </div>
</template>

<script>
import SimpleHeader from '@/components/SimpleHeader'
import SimpleFooter from '@/components/SimpleFooter'
import { getDepositHistory, getWithdrawHistory, getTransferHistory } from '@/api'
import utils from '@/utils/misc'
import { findIndex } from '@/utils/misc'

export default {
  name: 'FinanceRecord',
  components: { SimpleHeader, SimpleFooter },
  mixins: [],
  data() {
    return {
      loading:false,
      noMore: false,
      curType: 1,
      pageSize: 20,
      lastId: 0,
      dataList: []
    }
  },
  watch: {
    accountList() {
      this.setAccount()
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo
    },
    coinMsg() {
      return this.$store.state.coins
    },
    configObj() {
      return window.configObj
    },
    accountCates() {
      return this.$store.state.accountCates
    },
    disabled () {
      return this.loading || this.noMore
    }
  },
  beforeMount() {
    if (this.$route.query.type) {
      this.curType = +this.$route.query.type
    }
    this.loadData()
  },
  methods: {
    filterTime(stamp) {
      return utils.formatDate(stamp*1000, true)
    },
    filterStatusName(code) {
      const map = {
        1: 311,
        2: 309,
        3: 310
      }
      return this.$t(map[code])
    },
    filterCateName(cate) {
      for(const item of this.accountCates) {
        if (item.category === cate) {
          return item.name
        }
      }
    },
    switchType(type) {
      this.curType = type
      this.lastId = 0
      this.dataList = []
      this.loadData(true)
    },
    loadData(clear) {
      this.loading = true
      const apiMap = {
        1: getDepositHistory,
        2: getWithdrawHistory,
        3: getTransferHistory,
      }
      apiMap[this.curType]({
        pageSize: this.pageSize,
        lastId: this.lastId
      }).then(res => {
        res.list = res.list || []
        if (clear) {
          this.noMore = false
          this.dataList = res.list
        } else {
          this.dataList = [...this.dataList, ...res.list]
        }
        if (res.list.length > 0) {
          this.lastId = res.list[res.list.length - 1].id
        }
        if (this.dataList.length && !res.list.length) {
          this.noMore = true
        }
        if (res.list.length < 20) {
          this.noMore = true
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.finance-page{
  min-height: 100vh;
  background: #fff;
}
.tabs{
  margin: 10px 0px 20px 200px;
  color: #707A8A;
  font-size: 16px;
  .item{
    display: inline-block;
    cursor: pointer;
    padding: 8px 24px;
    margin-right: 12px;
    &.active{
      background-color: $primaryColor;
      color: #fff;
      border-radius: 8px;
    }
  }
}
.fund-box{
  min-height: 500px;
  color: #1E2329;
  width: 1000px;
  border-radius: 8px;
  margin: 5vh auto;
  background: #fff;
  border: 1px solid $borderColor;
  .infinite-list-wrapper{
    height: 500px;
  }
  .scroll-head{
    padding: 16px 20px;
    .head-item{
      flex: 1;
      color: #666;
    }
  }
  .s-head{
    padding: 16px 20px;
    border-bottom: 1px solid $borderColor;
    background: #f2f6fa;
  }
  .list{
    padding: 0px 20px;
    .list-item{
      height: 46px;
      line-height: 46px;
      border-bottom: 1px solid $borderColor;
      .cell{
        flex: 1;
      }
    }
  }
}
  .finance-page{
    min-height: 100vh;
    // background-color: #fff;
  }
  .ml{
    width: 1000px;
    margin: 0 auto;
  }
</style>